@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'vmSpinner.scss';
@import 'keen-slider/keen-slider.min.css';

html {
  font-family: 'avenir-roman';
}

/* Scroll bar styling */
/* width */
::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0);
  width: 0px;
  height: 0px;
  border-radius: 0px;

  &:hover {
    background: rgba(255, 255, 255, 0);
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  width: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0);
  width: 0px;
}

/* End Scroll bar styling */

/* Add Font Avenir */
@font-face {
  font-family: avenir-roman;
  src: url(../../public/fonts/AvenirLTProRoman.ttf) format('truetype');
}
@font-face {
  font-family: avenir-medium;
  src: url(../../public/fonts/AvenirLTProMedium.ttf) format('truetype');
}
@font-face {
  font-family: avenir-black;
  src: url(../../public/fonts/AvenirLTProBlack.ttf) format('truetype');
}
.avenir-roman {
  padding-top: 2.4px;
  font-family: avenir-roman;
}
.avenir-black {
  padding-top: 2.4px;
  font-family: avenir-black;
}
.avenir-book {
  padding-top: 2.4px;
  font-family: avenir-book;
}

[class^='number-slide'],
[class*=' number-slide'] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

@media print {
  @page {
    margin: 0;
    size: A4 landscape;
  }

  // .footer-cetak {
    // position: fixed;
    // bottom: 24mm;
    // left: -20mm;
    // transform: rotate(90deg);
  // }
}
